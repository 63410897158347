<template>
    <vue-plotly style="width:100%;" :data="data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'StackedBarChart',
  components: { VuePlotly },
  props: {
    ytitle: {
      type: String,
      default: ''
    },
    xtitle: {
      type: String,
      default: ''
    },
    name1: String,
    x1: Array,
    y1: Array,
    name2: {
      type: String,
      default: ''
    },
    x2: {
      type: Array,
      default () {
        return []
      }
    },
    y2: {
      type:Array,
      default () {
        return []
      }
    },
    name3: {
      type: String,
      default: ''
    },
    x3: {
      type: Array,
      default () {
        return []
      }
    },
    y3: {
      type:Array,
      default () {
        return []
      }
    },
    name4: {
      type: String,
      default: ''
    },
    x4: {
      type: Array,
      default () {
        return []
      }
    },
    y4: {
      type:Array,
      default () {
        return []
      }
    },
    name5: {
      type: String,
      default: ''
    },
    x5: {
      type: Array,
      default () {
        return []
      }
    },
    y5: {
      type:Array,
      default () {
        return []
      }
    },
    showlegend: {
      type: Boolean,
      default: true
    },
     legendAnchor: {
      type: Number,
      default: 1
    },
    legend_fontsize:{
      type: Number,
      default: 8
    },
    height: {
      type: Number,
      default: 420
    },
    title: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: Number,
      default: 140
    },
    marginTop: {
      type: Number,
      default: 10
    },
    tickangle: {
      type: Number,
      default: 0
    },
    niveau: {
      type: String,
      default: ''
    },
    ticksize:{
      type: Number,
      default:10
    }
  },
  data () {
    return {
      colorbar1: [],
      colorbar2: [],
      colorbar3: [],
      colorbar4: [],
      colorbar5: [],
      layout: {
        hovermode: 'closest',
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: {
          l: 50,
          r: 50,
          b: this.marginBottom,
          t: this.marginTop,
          pad: 0},
        barmode: 'stack',
         uniformtext:{
          minsize:10,
          mode:'hide'},
        showlegend: this.showlegend,
        legend: {
          xanchor:'left',
          yanchor:"bottom",
          orientation: "v",
          x: this.legendAnchor,
          y:  1,
          font: this.legend_fontsize
        },
        height: this.height,
        xaxis: {
          type: 'category',
          automargin:true,
          tickangle: this.tickangle,
          tickfont:{
            size: this.ticksize
          },
          title: {
            standoff:20,
            text: 'Regio',
            font: {
              family: 'Gotham',
              size: 12
            },
          }
        },
        title: {
          text: this.title,
          font: {
            family: 'Gotham',
            size: 14
          },
          xref: 'paper',
          x: 0.05,
          y: 0.99
        },
        yaxis: {
          automargin:true,
          title: {
            standoff:20,
            text: this.ytitle,
            font: {
              family: 'Gotham',
              size: 12
            }
          },
        }
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      },
    }
  },
  computed: {
    data () {
      return [
        {
          type: 'bar',
          name: this.name1,
          x: this.x1,
          y:this.y1,
          text: this.y1 ,
          textfont:{size:10},
          textposition: 'inside',
          textangle : 0,
          hoverinfo: this.x1,
          marker: {
            color: this.colorbar1
          }
        },
        {
          type: 'bar',
          name: this.name2,
          x: this.x2,
          y:this.y2,
          text: this.y2 ,
          textfont:{size:10},
          textposition: 'inside',
          textangle : 0,
          hoverinfo: this.x2,
          marker: {
            color: this.colorbar2
          }
        },
        { type: 'bar',
        name: this.name3,
        x: this.x3,
        y: this.y3,
        text: this.y3 ,
        textfont:{size:10},
        textposition: 'inside',
        textangle : 0,
        hoverinfo: this.x3,
        marker: {
          color: this.colorbar3
          }
        },
        { type: 'bar',
        name: this.name4,
        x: this.x4,
        y: this.y4,
        text: this.y4 ,
        textfont:{size:10},
        textposition: 'inside',
        textangle : 0,
        hoverinfo: this.x4,
        marker: {
          color: this.colorbar4
          }
        },
        { type: 'bar',
        name: this.name5,
        x: this.x5,
        y: this.y5,
        text: this.y5 ,
        textfont:{size:10},
        textposition: 'inside',
        textangle : 0,
        hoverinfo: 'none',
        marker: {
          color: this.colorbar5
          }
        }
      ]
    }
  },
  watch: {
    '$store.state.selectedRegion': function () {
      this.getColors(this.x1, this.x2, this.x3, this.x4, this.x5)
    },
    x1 () {
      this.getColors(this.x1, this.x2, this.x3, this.x4, this.x5)
      this.setXtitle()
    }
  },
  mounted () {
    this.getColors(this.x1, this.x2, this.x3, this.x4, this.x5)
    this.setXtitle()
  },
  methods: {
    setXtitle () {
      if (this.xtitle != '')
        this.layout.xaxis.title.text = this.xtitle
      else if (this.x1.length>35) {
        this.layout.xaxis.title.text='Dementienetwerk'
      }
      else if (this.niveau==='dementienetwerk') {
        this.layout.xaxis.title.text='Dementienetwerk'
      }
      else {
        this.layout.xaxis.title.text='Zorgkantoor'
      }
    },
    getColors (x1, x2, x3, x4, x5) {
      var colorArray1 = [];
      var colorArray2 = [];
      var colorArray3 = [];
      var colorArray4 = [];
      var colorArray5 = [];
      for (var i = 0; i<x1.length; i++) {
        if (x1[i] === this.$store.state.selectedRegion) {
          colorArray1.push('rgba(20,66,115,1.00))')
        }
        else if (x1[i] === 'Landelijk') {
          colorArray1.push('rgba(51,127,100,1.00))')
        }
        else {
          colorArray1.push('rgba(140, 140, 140,1.00)')
        }
      }
      for (var j = 0; j<x2.length; j++) {
        if (x2[j] === this.$store.state.selectedRegion) {
          colorArray2.push('rgba(20,66,115,0.8))')
        }
        else if (x2[j] === 'Landelijk') {
          colorArray2.push('rgba(51,127,100,0.8))')
        }
        else {
          colorArray2.push('rgba(140, 140, 140,0.8)')
        }
      }
      for (var k = 0; k<x3.length; k++) {
        if (x3[k] === this.$store.state.selectedRegion) {
          colorArray3.push('rgba(20,66,115,0.6))')
        }
        else if (x3[k] === 'Landelijk') {
          colorArray3.push('rgba(51,127,100,0.6))')
        }
        else {
          colorArray3.push('rgba(140, 140, 140,0.6)')
        }
      }
      for (var l = 0; l<x4.length; l++) {
        if (x4[l] === this.$store.state.selectedRegion) {
          colorArray4.push('rgba(20,66,115,0.4))')
        }
        else if (x4[l] === 'Landelijk') {
          colorArray4.push('rgba(51,127,100,0.4))')
        }
        else {
          colorArray4.push('rgba(140, 140, 140,0.4)')
        }
      }
      for (var m = 0; m<x5.length; m++) {
        if (x5[m] === this.$store.state.selectedRegion) {
          colorArray5.push('rgba(20,66,115,0.2))')
        }
        else if (x5[m] === 'Landelijk') {
          colorArray5.push('rgba(51,127,100,0.2))')
        }
        else {
          colorArray5.push('rgba(140, 140, 140,0.2)')
        }
      }
      this.colorbar1 = colorArray1
      this.colorbar2 = colorArray2
      this.colorbar3 = colorArray3
      this.colorbar4 = colorArray4
      this.colorbar5 = colorArray5
    }
  }
}

</script>
